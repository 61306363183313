import * as React from "react";
import * as Styles from "../styles/form3.module.scss";
import i18next from "../i18n.js";
import moment from "moment";

const InqueryForm = ({ topic, title, futureEvents }) => {
  var { t } = i18next;
  var [buttonText, setButtonText] = React.useState(t("contactFormButton"));
  var [cssClass, setCssClass] = React.useState("");
  const hasFutureEvents = futureEvents != null && futureEvents.length >= 1;
  return (
    <div>
      <form
        action="/api/submit-inquiry-form"
        method="POST"
        onSubmit={(event) => {
          setButtonText("Nachricht gesendet");
          setCssClass("green");
        }}
        class={Styles.form}
        target="hiddenFrame"
      >
        <fieldset>
          <h2>{title}</h2>
          <div class={Styles.formGroup}>
            <label class={Styles.label} for="Name">
              {t("contactFormName")}
              <div class={Styles.field}>
                <input
                  id="Name"
                  name="name"
                  type="text"
                  placeholder="Vor- und Nachname"
                  class="form-control input-md"
                  required="required"
                  aria-required="true"
                />
              </div>
            </label>
          </div>

          <div class={Styles.formGroup}>
            <label class={Styles.label} for="email">
              {t("contactFormEmail")}
              <div class={Styles.field}>
                <input
                  id="email"
                  name="email"
                  type="text"
                  placeholder="name@company.com"
                  class="form-control input-md"
                  required="required"
                  aria-required="true"
                />
              </div>
            </label>
          </div>

          <div class={Styles.hidden}>
            <label class={Styles.label} for="email">
              Honey Pot
              <div class={Styles.field}>
                <input
                  id="hp"
                  name="hp"
                  type="text"
                  class="form-control input-md"
                  placeholder="Nur von Robots auszufüllen"
                />
              </div>
            </label>
          </div>

          <div class={Styles.hidden}>
            <label class={Styles.label} for="topics">
              {t("contactFormTopics")}
              <div class={Styles.field}>
                <input
                  id="topics"
                  name="topics"
                  type="text"
                  placeholder="Welche Themen sind für Sie interessant?"
                  class="form-control input-md"
                  value={topic}
                />
              </div>
            </label>
          </div>

          <div class={Styles.formGroup}>
            <label class={Styles.label} for="participants">
              {t("contactFormParticipants")}
              <div class={Styles.field}>
                <input
                  id="participants"
                  name="participants"
                  type="text"
                  placeholder="Wie viele Teilnehmer sind geplant?"
                  class="form-control input-md"
                />
              </div>
            </label>
          </div>

          <div class={Styles.formGroup}>
            <label class={Styles.label} for="timeframe">
              {t("contactFormTimeframe")}
              <div class={Styles.field}>
                <input
                  id="timeframe"
                  name="timeframe"
                  type="text"
                  placeholder="Haben Sie schon einen Zeitrahmen im Blick?"
                  class="form-control input-md"
                />
              </div>
            </label>
          </div>

          {hasFutureEvents ? (
            <div className={Styles.radioButtonGroup}>
              <h4>Schulungsart</h4>
              <label for="inhouse">
                <input
                  type="radio"
                  id="inhouse"
                  name="Type"
                  value="Inhouse"
                ></input>
                Firmenschulung, bei Ihnen vor Ort{" "}
              </label>
              <label for="inhouseRemote">
                <input
                  type="radio"
                  id="inhouseRemote"
                  name="Type"
                  value="Inhouse Remote"
                ></input>
                Firmenschulung Remote
              </label>
              <label for="inhouseMA">
                <input
                  type="radio"
                  id="inhouseMA"
                  name="Type"
                  value="Inhouse Mannheim"
                ></input>
                Firmenschulung, bei uns in Mannheim
              </label>
              <label for="open">
                <input type="radio" id="open" name="Type" value="Open"></input>
                Offene Schulung in Mannheim
              </label>

              <h4>Offene Schulungstermine</h4>
              {futureEvents.map((event) => (
                <label for={event.StartDate}>
                  <input
                    type="radio"
                    id={event.StartDate}
                    name="StartDate"
                    value={event.StartDate}
                  />
                 {event.DateText}{" "}
                   {event.Remark?(<div class={Styles.eventRemark}>({event.Remark})</div>):null}
                </label>
              ))}
              <p>
                Die Anmeldung für offene Schulungen ist unverbindlich. Wir
                schicken Ihnen daraufhin ein Angebot, ggf. incl. Rabatte, dass
                Sie annehmen können.
              </p>
              <p>
                Bei Anmeldung bis 2 Wochen vor der Veranstaltung gewähren wir
                einen Frühbucherrabatt von 15% auf den angezeigten Preis. Für
                Gruppen ab 3 Teilnehmern auf der gleichen Rechnung gibt es einen
                Teamrabatt von 25%.
              </p>
            </div>
          ) : null}

          <div class={Styles.formGroup}>
            <label class={Styles.label} for="message">
              {t("contactFormMessage")}
              <div class={Styles.field}>
                <textarea
                  class="form-control"
                  id="message"
                  name="message"
                ></textarea>
              </div>
            </label>
          </div>

          <button type="submit" className={cssClass}>
            {buttonText}
          </button>
          <iframe
            name="hiddenFrame"
            title="Status Message"
            style={{ height: "7em", border: "none", width: "100%" }}
          ></iframe>
        </fieldset>
      </form>
    </div>
  );
};
export default InqueryForm;
